<template>
    <div class="container-fluid d-flex flex-column flex-grow-1 mt-3">
        <div class="animated fadeIn">
            <b-row>
                <b-col lg="12">
                    <transition name="fade">
                        <b-card no-body>
                            <div slot="header">
                                <i class="fa fa-edit"></i> Generate Winners
                                <div class="card-header-actions">
                                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                                        <i class="icon-arrow-up"></i>
                                    </b-link>
                                </div>
                            </div>
                            <b-collapse id="collapse1" visible>
                                <b-card-body>
                                    <b-row>
                                        <b-col sm="3">
                                            <TextBox v-model="item.numberOfWinners" placeholder="Name"
                                                     :addClass="{'is-invalid': $v.item.numberOfWinners.$error}">Number of Winners
                                            </TextBox>

                                        </b-col>
                                        <b-col sm="9">
                                            <fieldset class="form-group">
                                                <div>
                                                    <label>Winners</label>
                                                    <table class="table table-bordered table-sm">
                                                        <tbody>
                                                        <tr>
                                                            <td>First Name</td>
                                                            <td>Last Name</td>
                                                            <td>Phone</td>
                                                            <td>User Name</td>
                                                            <td>Unique Code</td>
                                                        </tr>
                                                        <tr v-for="(winner, index) in item.winners" :key="index">
                                                            <td>
                                                                <TextBox v-model="winner.firstName"/>
                                                            </td>
                                                            <td>
                                                                <TextBox v-model="winner.lastName"/>
                                                            </td>
                                                            <td>
                                                                <TextBox v-model="winner.phoneNumber"/>
                                                            </td>
                                                            <td>
                                                                <TextBox v-model="winner.userName"/>
                                                            </td>
                                                            <td>
                                                                <TextBox v-model="winner.uniqueCode"/>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </fieldset>
                                        </b-col>
                                    </b-row>
                                    <div class="d-flex justify-content-end">
                                        <div class="form-actions">
                                            <b-button right type="submit" variant="primary" @click="onGenerate">Generate
                                            </b-button>
                                            <router-link type="cancel" class="btn btn-secondary" variant="secondary"
                                                         :to="'/competitions'">Cancel
                                            </router-link>
                                        </div>
                                    </div>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                    </transition>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
    import {required} from 'vuelidate/lib/validators'

    export default {
        name: "GenerateWinner",
        data() {
            return {
                item: {
                    numberOfWinners: '1',
                    winners: []
                },
            }
        },
        created() {},

        computed: {},
        methods: {
            onGenerate() {
                if (this.$v.item.$invalid) {
                    this.$v.$touch()
                    return
                }
                let axios = this.axios.get('competition/winners-generate', {params: {numberOfWinners: this.item.numberOfWinners}})
                axios.then((res) => {
                    if (res.data) {
                        this.item = res.data.data
                        this.$toastr.info('Winners generated')
                    } else {
                        this.$toastr.error(res.data.message)
                    }
                })
            },
        },
        validations: {
            item: {
                numberOfWinners: {
                    required
                }
            }
        }
    }
</script>

<style scoped>

</style>